import { useState, useEffect } from 'react';
import { database } from '../services/firebase';
import { useAuth } from './useAuth';

type QuestionType = {
  id: string;
  author: {
    avatar: string;
    name: string;
  };
  content: string;
  isAnswered: boolean;
  isHighlighted: boolean;
  likeCount: number;
  likeId: string | undefined;
}

type FirebaseQuestions = Record<string, {
  author: {
    avatar: string;
    name: string;
  };
  content: string;
  isAnswered: boolean;
  isHighlighted: boolean;
  likes: Record<string, {
    authorId: string
  }>
}>

export function useRoom(roomId: string) {
  const { user } = useAuth()
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    // buscar os dados da sala no firebase
    const roomRef = database.ref(`rooms/${roomId}`);

    roomRef.on('value', room => {
      const databaseRoom = room.val();
      const firebaseQuestions: FirebaseQuestions = databaseRoom.questions ?? {};

      const parsedQuestions = Object.entries(firebaseQuestions).map(([key, value]) => {
        return {
          id: key,
          content: value.content,
          author: value.author,
          isHighlighted: value.isHighlighted,
          isAnswered: value.isAnswered,
          likeCount: Object.values(value.likes ?? {}).length,
          likeId: Object.entries(value.likes ?? {}).find(([key, like]) => like.authorId === user?.id)?.[0]
          // ?.[0] >>> "Se houver algum conteúdo para retornar, acesse o index 0; se não, retorne undefined."
        }
      })

      setTitle(databaseRoom.title)
      setQuestions(parsedQuestions)
    }) // Essas instruções estão no documentação do firebase. Serão obtidos todos os dados contidos na sala.

    return () => {
      roomRef.off('value'); // Boa prática: Desligar o event listner sempre que não utilizar o componente
    }

  }, [roomId, user?.id]); // É um hook que DISPARA UM EVENTO |() => {}| sempre que alguma INFORMAÇÃO MUDAR |[]|
  // [roomId] >>> Toda vez que o argumento de url que contém o id da sala mudar, o useEffect executará seu código.

  return { questions, title }
}