import { ReactNode } from 'react';
import './styles.scss';
import cx from 'classnames';

type QuestionProps = {
  content: string;
  author: {
    name: string;
    avatar: string;
  };
  children?: ReactNode;
  isHighlighted?: boolean;
  isAnswered?: boolean;
}

// {`className`} >>> Sintaxe para poder manipular a classe via JavaScript

export function Question({ content, author, children, isAnswered = false, isHighlighted = false }: QuestionProps) {
  return (
    <div className={cx(
      'question',
      { answered: isAnswered }, // key = nome da classe | value = variável booleana
      { highlighted: isHighlighted && !isAnswered},
    )}
    >
      <p>
        {content}
      </p>
      <footer>
        <div className="user-info">
          <img src={author.avatar} alt={author.name} />
          <span>{author.name}</span>
        </div>
        <div>
          {children}
        </div>
      </footer>
    </div>
  );
}